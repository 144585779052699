import React from "react";
import luceroLogo from "../assets/images/website-icon.png";
import banner from "../assets/images/banner.jpg";

const Banner = props => (
  <section id="banner" className="major" style={{ backgroundImage: `url(${banner})` }}>
    <div className="inner">
      <header className="major">
        <div>
          <img src={luceroLogo} alt="Plastico Lucero" style={{ height: 100 }} />
        </div>
        <h1>&nbsp;Plasticos Lucero&nbsp;</h1>
      </header>
      <div className="content">
        <p>
          Mas de 40 años haciendo productos
          <br />
          de excelente diseño y calidad
        </p>
        <ul className="actions">
          <li>
            <a href="#one" className="button next scrolly">
              MIRA LO QUE HACEMOS
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Banner;
