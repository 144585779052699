import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import Nosotros from "../components/Nosotros";

import art_700_703 from "../assets/images/articulos/bazar/700-703.jpg";
import art_290_320 from "../assets/images/articulos/infantil/290-320.jpg";
import { getDestacados } from "../utils/articulos";
import SHI from "../assets/images/fama/SHI.jpg";

const Destacado = props => (
  <div className="col-4">
    <span className="image fit">
      <img
        src={props.articulo.img}
        alt={props.articulo.descripcion}
        title={props.articulo.descripcion}
        style={{ borderRadius: 10 }}
      />
    </span>
    <div style={{ textAlign: "center", marginTop: -30 }}>Art. {props.articulo.codigo}</div>
  </div>
);

class HomeIndex extends React.Component {
  render() {
    const destacados = getDestacados();
    return (
      <Layout>
        <Helmet
          title="Plasticos Lucero"
          meta={[
            {
              name: "description",
              content: `Empresa Argentina dedicada al diseño y fabricación de articulos plásticos de bazar e infantil para mayoristas`,
            },
            {
              name: "keywords",
              content:
                "mayorista, por mayor, articulos, productos, plastico, fabrica, bazar, bazares, matricería, inyección",
            },
            {
              name: "language",
              content: "ES",
            },
            { property: "og:title", content: "Plasticos Lucero - Fabricación de productos plásticos para mayoristas" },
            {
              property: "og:description",
              content: `Empresa Argentina dedicada al diseño y fabricación de artículos plásticos de bazar e infantil para mayoristas`,
            },
            { property: "og:image", content: art_290_320 },
            { property: "og:url", content: "https://www.plasticoslucero.com.ar" },
            { property: "og:site_name", content: "Plasticos Lucero" },
            { name: "google-site-verification", content: "_g5cbh3Gut2PGooVMbipldaGUPl1AxieQ1mHfRd5kw0" },
          ]}
        />

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${art_290_320})` }}>
              <header className="major">
                <h3>Linea Infantil</h3>
              </header>
              <Link to="/infantil" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${art_700_703})` }}>
              <header className="major">
                <h3>Linea Bazar</h3>
              </header>
              <Link to="/bazar" className="link primary" />
            </article>
          </section>
          <section id="prodDest">
            <div className="inner">
              <header className="major">
                <h2>Destacados</h2>
              </header>
              <div className="grid-wrapper">
                {destacados.map(pd => (
                  <Destacado articulo={pd} key={pd.codigo} />
                ))}
              </div>
              <ul
                className="actions"
                style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 15 }}>
                <li>
                  <Link to="/bazar" className="button next">
                    VER MAS
                  </Link>
                </li>
              </ul>
            </div>
          </section>

          <section id="fama">
            <div className="inner">
              <header className="major">
                <h2>Somos Famosos!</h2>
              </header>
              <div className="grid-wrapper">
                <div className="col-6">
                  <span className="image fit">
                    <img src={SHI} alt="" title="" style={{ borderRadius: 10 }} />
                  </span>
                </div>
                <div className="col-6">
                  <p>
                    Algunos de nuestros productos aparecieron en distintos programas de televisión como tambien en
                    comerciales, hasta inclusive en una de las cuentas mas famosas de "Memes" de Argentina!
                  </p>
                  <ul
                    className="actions"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 25 }}>
                    <li>
                      <Link to="/fama" className="button next">
                        VER MAS
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <Nosotros />
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
