import React from "react";

const Nosotros = props => (
  <section id="nosotros">
    <div className="inner">
      <header className="major">
        <h2>Nosotros</h2>
      </header>
      <div className="grid-wrapper">
        <div className="col-4">
          <h3>Experiencia</h3>
          <p>
            Somos una empresa Argentina dedicada al diseño, fabricación y distribución de productos plásticos. Con una
            trayectoria superior a los <strong>40 años en el mercado nacional</strong>, hemos recorrido este camino,
            gracias a la superación de diferentes desafíos y a la <strong>fidelidad</strong> de nuestros clientes y
            proveedores.
          </p>
        </div>
        <div className="col-4">
          <h3>Producción propia</h3>
          <p>
            Contamos con <strong>matriceria propia.</strong> Nuestro departamento de matriceria realiza un trabajo de
            gran precisión y desarrolla dispositivos mecánicos de todo tipo. Poseemos{" "}
            <strong>inyectoras propias</strong> con capacidad de inyección hasta 500 grm y sistema de control
            estadístico de producción y calidad.
          </p>
        </div>
        <div className="col-4">
          <h3>Calidad</h3>
          <p>
            Hemos cuidado especialmente la calidad de los materiales utilizados y su terminación. Estamos a la
            <strong> vanguardia</strong> de los últimos avances tecnológicos para ofrecer la mejor calidad posible en
            nuestros productos.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Nosotros;
